<template>
	<div class="emb-gadget-wrap  mt-10">
		<div class="gadget-content section-gap">
			<v-container grid-list-xl py-0>
				<v-layout row wrap>
					<v-flex xs12 sm12 md12 lg12 xl12>
						<v-card>
							<v-toolbar
								color="primary"
								dark
								flat
								centered
								next-icon="mdi-arrow-right-bold-box-outline"
								prev-icon="mdi-arrow-left-bold-box-outline"
								show-arrows
							>
								<v-text-field
									v-model="input"
									append-icon="mdi-microphone"
									flat
									hide-details
									label="Buscar..."
									prepend-inner-icon="mdi-magnify"
									solo-inverted
									autofocus
									dense
									@input="searchItems()"
								></v-text-field>

								<template v-slot:extension>
									<v-tabs
										v-model="tabs"
										centered
										show-arrows
										@change="changeTab()"
									>
										<v-tab @click.prevent="setAllItems()">
											TODOS
										</v-tab>
										<v-tab
											v-for="(category, i) in categories"
											:key="i"
											@click.prevent="getItemsByCategory(category.id)"
										>
											{{ category.name }}
										</v-tab>
									</v-tabs>
								</template>
							</v-toolbar>
						</v-card>
					</v-flex>

					<v-flex xs12 sm12 md12 lg12 xl12>
						<v-row dense>
							<v-col
								class="d-flex align-items-stretch"
								v-for="(item, i) in items"
								:key="i"
								cols="12"
								xl="3"
								lg="6"
								md="6"
								sm="6"
							>
								<v-card hover dark color="secondary">
									<v-img
										:src="item.image"
										class="white--text align-center"
										gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.9)"
										height="200px"
									>
										<p class="text-center mt-3 ma-1 white--text">
											{{ item.name }}
										</p>
									</v-img>

									<p class="text-center caption mt-3">
										{{
											item.description
												? item.description
												: 'NO TIENE DESCRIPCIÓN'
										}}
									</p>

									<v-card-actions>
										<v-btn
											v-if="ifItemExistInWishlist(item)"
											@click="addItemToWishlist(item)"
											class="px-0"
											icon
										>
											<v-icon class="accent--text">mdi-heart</v-icon>
										</v-btn>
										<v-btn
											v-else
											@click="addItemToWishlist(item)"
											class="px-0"
											icon
										>
											<v-icon class="grey--text">mdi-heart</v-icon>
										</v-btn>
										<v-spacer></v-spacer>
										<h6 class="mt-2">
											<strong
												><emb-currency-sign></emb-currency-sign>
												{{ item.delivery_price }}</strong
											>
										</h6>
									</v-card-actions>
									<v-card-actions>
										<el-button
											type="primary"
											size="medium"
											class="btn-block"
											round
											@click="addProductToCart(item)"
										>
											Ordenar
										</el-button>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-flex>
				</v-layout>

				<v-layout align-center justify-end class="mt-4">
					<v-flex xs12 sm12 md12 lg12 xl12>
						<div class="layout justify-end" v-if="cart && cart.length > 0">
							<emb-sidebar-panel></emb-sidebar-panel>
							<v-btn class="mx-3" color="accent" @click="payment">
								<v-icon dark left small>mdi-credit-card</v-icon>
								Proceder con el pago
							</v-btn>
						</div>
					</v-flex>
				</v-layout>
			</v-container>
		</div>
		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:isRouteMenu="true"
			:lat="lat"
			:lng="lng"
			:draggable="true"
			:autoAdd="true"
			:item="item"
			@add="addProductToCart"
		>
		</emb-location-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import filter from 'lodash/filter';

export default {
	data() {
		return {
			resource: 'user/orders',
			showLocationDialog: false,
			categories: [],
			allItems: [],
			items: [],
			item: null,
			lat: null,
			lng: null,
			tabs: 2,
			input: null,
		};
	},
	computed: {
		...mapGetters(['cart', 'wishlist', 'orderData', 'location', 'loggedIn']),
	},
	async created() {
		await this.getDynamicTables();
		await this.getExchangeRateByDate();

		await this.$eventHub.$on('getItemsByCategory', (categoryId) => {
			// this.getItemsByCategory(categoryId)
			// this.tabs = 2
			console.log(categoryId);
		});
	},
	methods: {
		...mapActions({
			changeQuantityHandler: 'changeQuantityHandler',
			addNotification: 'addNotification',
		}),
		changeTab() {
			// console.log(this.tabs, 'auto chane');
		},
		getExchangeRateByDate() {
			if (this.orderData.exchange_rate_sale === 'USD') {
				this.searchExchangeRateByDate(this.orderData.date_of_issue).then((response) => {
					this.orderData.exchange_rate_sale = response;
				});
			}
		},
		async getDynamicTables() {
			await this.$http.get(`/${this.resource}/dynamic-tables`).then((response) => {
				this.categories = response.data.categories;
				this.allItems = response.data.items;

				this.setAllItems();
			});
		},
		searchItems() {
			if (this.input.length > 2) {
				let parameters = `input=${this.input}`;
				this.$http.get(`/${this.resource}/search/items?${parameters}`).then((response) => {
					this.items = response.data;
				});
			} else {
				this.setAllItems();
			}
		},
		getItemsByCategory(categoryId) {
			this.items = filter(this.allItems, { category_id: categoryId });
			// this.tabs = categoryId
		},
		setAllItems() {
			this.items = this.allItems;
		},
		addProductToCart(item) {
			if (this.location) {
				if (!this.ifItemExistInCart(item)) {
					setTimeout(() => {
						this.$store.dispatch('addProductToCart', item);
					}, 100);

					this.addNotification({
						show: true,
						type: 'success',
						message: 'El Producto ha sido agregado al carrito satisfactoriamente',
					});
				} else {
					this.addNotification({
						show: true,
						type: 'success',
						message: 'Cantidad actualizada en el carrito',
					});
				}
			} else {
				this.item = item;
				this.showLocationDialog = true;
			}
		},
		/**
		 * method for checking if item exists in cart
		 */
		ifItemExistInCart(result) {
			let exists = false;
			if (this.cart) {
				for (let item of this.cart) {
					if (item.item_id == result.id) {
						exists = true;
						item.quantity = item.quantity + 1;
						this.changeQuantityHandler(item);
					}
				}
			}
			return exists;
		},
		// this method is use to add a product in wishlist
		addItemToWishlist(data) {
			if (this.ifItemExistInWishlist(data)) {
				this.addNotification({
					show: true,
					type: 'error',
					message: 'Producto existe en la lista de deseos.',
				});
			} else {
				this.addNotification({
					show: true,
					type: 'success',
					message: 'Producto agregado a la lista de deseos',
				});

				setTimeout(() => {
					this.$store.dispatch('addItemToWishlist', data);
				}, 100);
			}
		},
		/**
		 * This Function Is use to check weather the product exist in the wishlist
		 * Return boolean
		 */
		ifItemExistInWishlist(result) {
			let exists = false;
			for (let item of this.wishlist) {
				if (item.id == result.id) {
					exists = true;
				}
			}
			return exists;
		},
		onCalculateItemTotal(item) {
			return this.$store.dispatch('calculateItemTotal', item);
		},
		payment() {
			if (this.loggedIn) {
				this.$router.push({ name: 'payment' });
			} else {
				this.$router.push({ name: 'login' });
			}
		},
	},
};
</script>
